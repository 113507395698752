var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mh" },
    [
      _c(
        "MatchHeadTeam",
        _vm._b(
          { attrs: { "map-id": _vm.mapId } },
          "MatchHeadTeam",
          _vm.winnerTeam,
          false
        )
      ),
      _c("div", { staticClass: "mh--info" }, [
        _c(
          "div",
          { staticClass: "mh--icons" },
          [
            _c("VodStatusIndicator", { attrs: { status: _vm.vodStatus } }),
            _c("BookmarkToggle", {
              attrs: { value: _vm.bookmark },
              on: {
                update: function($event) {
                  return _vm.$emit("set:bookmark", $event)
                }
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mh--event" },
          [
            _c(
              "EventCell",
              _vm._b(
                { attrs: { variant: "row" } },
                "EventCell",
                _vm.event,
                false
              )
            )
          ],
          1
        ),
        _c("div", { staticClass: "mh--score" }, [
          _c("span", { staticClass: "mh--score--num mh--score--num__winner" }, [
            _vm._v(_vm._s(_vm.winnerTeam ? _vm.winnerTeam.score : "-"))
          ]),
          _vm._v(" : "),
          _c("span", { staticClass: "mh--score--num mh--score--num__loser" }, [
            _vm._v(_vm._s(_vm.loserTeam ? _vm.loserTeam.score : "-"))
          ])
        ]),
        _c(
          "div",
          { staticClass: "mh--info" },
          [
            _c("div", { staticClass: "mh--info--date" }, [
              _vm._v(" " + _vm._s(_vm._f("localDate")(_vm.date)) + " ")
            ]),
            _c("div", { staticClass: "mh--info--date" }, [
              _vm._v(" " + _vm._s(_vm._f("localTime")(_vm.date)) + " ")
            ]),
            _c("MapCell", { attrs: { id: _vm.mapId, variant: "row" } })
          ],
          1
        )
      ]),
      _c(
        "MatchHeadTeam",
        _vm._b(
          { attrs: { "map-id": _vm.mapId } },
          "MatchHeadTeam",
          _vm.loserTeam,
          false
        )
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }