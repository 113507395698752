var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mht" },
    [
      _c("TeamCell", {
        attrs: {
          link: "",
          id: _vm.id,
          name: _vm.name,
          image: _vm.image,
          variant: "col",
          to: { query: { s: _vm.$route.query.s, map_ids: [_vm.mapId] } }
        }
      }),
      _c("Breakdown", { attrs: { breakdown: _vm.breakdown } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }