var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "MatchRoundSelectorItem",
    _vm._b(
      {
        class: { "first-half": _vm.round.first_half },
        attrs: {
          number: _vm.round.round_number + 1,
          winner: _vm.winner,
          loser: _vm.loser,
          highlighted: _vm.selected,
          selected: _vm.active,
          outcome: _vm.winner.won_by,
          tag: _vm.tag
        }
      },
      "MatchRoundSelectorItem",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }