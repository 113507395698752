var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.map
    ? _c(
        "ImgCell",
        _vm._b(
          {
            attrs: {
              image: _vm.map.splash_url,
              label: _vm.map.name,
              variant: _vm.variant,
              size: _vm.size
            }
          },
          "ImgCell",
          _vm.$attrs,
          false
        )
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }